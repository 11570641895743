<template>
  <v-card>
    <v-container>
      <v-card-title class="primary--text pt-0">
        Heredar tabla
      </v-card-title>
      <v-form
        v-model="isFormValidHeredar"
        ref="form-heredar"
        form="form-heredar"
        id="form-heredar"
        @submit.prevent="applySave()"
      >
        <v-row>
          <!-- <v-col cols="12" md="7" class="pb-0 py-0">
            <v-autocomplete
              ref="tablas-autocomplete"
              v-model.trim="nombreTablaSelected"
              :items="nombreTablas"
              :search-input.sync="tablasAutocompleteInput"
              item-text="value"
              item-value="id"
              @change="getNomencladoresTablaFac()"
              :filter="customFilterTablas"
              outlined
              :rules="rules.required"
              hide-no-data
              hint="Ingrese número o nombre de tabla"
              clearable
              cache-items
              return-object
              dense
              autocomplete="off"
              label="Nombre de tabla"
            >
              <template slot="item" slot-scope="{ item }">
                <v-list-item-content>
                  <v-list-item-title>{{ item.value }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-slot:append>
                <v-progress-circular
                  indeterminate
                  size="28"
                  v-if="tablasLoading"
                  color="primary"
                ></v-progress-circular>
              </template>
            </v-autocomplete>
          </v-col> -->
          <v-col cols="12" md="5" class="py-0 pr-0">
            <v-col cols="12" class="py-1 my-0 d-flex justify-center">
              <v-autocomplete
                ref="tablas-autocomplete"
                v-model.trim="nombreTablaSelected"
                :items="nombreTablas"
                :search-input.sync="tablasAutocompleteInput"
                item-text="value"
                item-value="id"
                @change="getNomencladoresTablaFac()"
                :filter="customFilterTablas"
                outlined
                :rules="rules.required"
                hide-details="auto"
                hide-no-data
                hint="Ingrese número o nombre de tabla"
                clearable
                cache-items
                return-object
                dense
                autocomplete="off"
                label="Nombre de tabla"
              >
                <template slot="item" slot-scope="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.value }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <template v-slot:append>
                  <v-progress-circular
                    indeterminate
                    size="28"
                    v-if="tablasLoading"
                    color="primary"
                  ></v-progress-circular>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" class="py-1 my-0 d-flex justify-center">
              <v-autocomplete
                v-model="tipoRedondeoSelected"
                :items="tipoRedondeo"
                return-object
                item-text="value"
                item-value="id"
                hide-details="auto"
                outlined
                dense
                autocomplete="off"
                label="Tipo de redondeo"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" class="py-1 my-0 d-flex justify-center">
              <currency-input
                v-model="porcentajeAumentoAran"
                :append-icon="porcentajeIcon"
                dense
                :hideDetails="'auto'"
                :rules="[
                  rules.decimalValidRanges(porcentajeAumentoAran, 0, 500)
                ]"
                :options="percentOptions"
                label="Porcentaje de aumento aranceles"
                outlined
              >
              </currency-input>
            </v-col>
          </v-col>
          <!-- Tabla de porcentajes  -->
          <v-col cols="12" md="7" class="py-0 ">
            <v-data-table
              :headers="porcentajesPorNomHeaders"
              :items="porcentajesPorNomItems"
              fixed-header
              loading-text="Cargando nomencladores de la tabla"
              :loading="loadingNomencladores"
              :items-per-page="5"
              class="elevation-5"
              dense
              item-key="nomId"
            >
              <template v-slot:top>
                <v-row>
                  <v-col cols="12" md="12" class="pb-1 pt-2">
                    <span class="primary--text py-0 pl-2 font-weight-medium"
                      >Porcentajes de aumento de prácticas por nomenclador</span
                    ></v-col
                  >
                </v-row>
              </template>
              <template v-slot:[`item.porcentaje`]="{ item }">
                <currency-input
                  v-model="item.porcentaje"
                  :options="percentOptions"
                  :readonly="false"
                  :appendIcon="editIcon"
                  :id="'itemPorcentaje'"
                  :outlined="false"
                  :flat="true"
                  :solo="false"
                  :rules="[rules.decimalValidRanges(item.porcentaje, 0, 500)]"
                  :hideDetails="'auto'"
                >
                </currency-input>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <!-- <v-row> -->
        <!-- <v-col cols="6" md="5" class="py-0 my-0 d-flex justify-center">
            <currency-input
              v-model="porcentajeAumentoFac"
              :append-icon="porcentajeIcon"
              dense
              :options="currencyOptions"
              :rules="porcentajeAumentoFac != null ? rules.maxDecimal100() : []"
              label="Porcentaje de aumento prácticas"
              outlined
            >
            </currency-input>
          </v-col> -->
        <!-- </v-row> -->
      </v-form>
      <v-card-actions class="pb-0 pt-3">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValidHeredar"
          form="form-heredar"
          color="primary"
          :loading="loadingBtn"
        >
          Aplicar
        </v-btn>
      </v-card-actions>
      <ConfirmDialog
        :text="textConfirmDialog"
        :openConfirm.sync="openConfirmDialog"
        @onConfirm="applyHeredar()"
      />
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import { mask } from "vue-the-mask";

export default {
  name: "HeredarTabla",
  directives: { mask },
  props: {
    tabla: { type: Object, required: false, default: null },
    arancelesLength: { type: Number, required: false, default: 0 },
    practicasLength: { type: Number, required: false, default: 0 }
  },
  components: { CurrencyInput, ConfirmDialog, CurrencyInput },
  data: () => ({
    textConfirmDialog:
      "La tabla posee códigos configurados que serán eliminados, ¿desea continuar?",
    openConfirmDialog: false,
    isFormValidHeredar: false,
    nombreTablaSelected: null,
    tablasLoading: false,
    tablasAutocompleteInput: null,
    loadingBtn: false,
    tipoRedondeoSelected: 0,
    tipoRedondeo: [
      {
        id: 0,
        value: "Sin redondeo"
      },
      {
        id: 1,
        value: "Unidad"
      },
      {
        id: 2,
        value: "Decena"
      }
    ],
    porcentajeAumentoFac: null,
    currencyOptions: {
      locale: "nl-NL",
      currency: "USD",
      currencyDisplay: "hidden",
      valueRange: {
        min: 0,
        max: 999999999999999
      },
      precision: undefined,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: true
    },
    percentOptions: {
      locale: "es-ES",
      currency: "USD",
      currencyDisplay: "hidden",
      valueRange: {
        min: 0,
        max: 500
      },
      hideCurrencySymbolOnFocus: false,
      hideGroupingSeparatorOnFocus: false,
      hideNegligibleDecimalDigitsOnFocus: false,
      autoDecimalDigits: false,
      useGrouping: false,
      accountingSign: false
    },
    porcentajesPorNomHeaders: [
      {
        text: "Nomenclador",
        value: "nomNom",
        align: "start",
        sortable: false
      },
      {
        text: "Porcentaje",
        value: "porcentaje",
        sortable: false,
        width: "25%",
        align: "center"
      }
    ],
    porcentajesPorNomItems: [],
    loadingNomencladores: false,
    editIcon: enums.icons.EDIT,
    porcentajeAumentoAran: null,
    infoNuevaActualizacion: null,
    porcentajeIcon: enums.icons.PERCENT_OUTLINE,
    rules: rules,
    observaciones: null,
    obsTabla: "",
    enums: enums,
    nombreTablas: [],
    isFormValidImportar: false,
    excelFile: null,
    loadingFile: false,
    creaUnidades: false,
    idTabProp: null,
    idT: null
  }),
  watch: {
    tablasAutocompleteInput(val) {
      if (val) {
        this.getTablasFilter();
      }
    }
  },
  methods: {
    ...mapActions({
      importaTablaFacturacion: "prestadores/importaTablaFacturacion",
      heredarTablaFac: "prestadores/heredarTablaFac",
      getNomencladoresTablas: "prestadores/getNomencladoresTablas",
      getTablasFacturacion: "prestadores/getTablasFacturacion",
      getFormatoProcesoImportacionXUnicaVez:
        "devengamientos/getFormatoProcesoImportacionXUnicaVez",
      exportTablaFacturacionImportacion:
        "prestadores/exportTablaFacturacionImportacion",
      setAlert: "user/setAlert"
    }),
    applySave() {
      if (this.practicasLength > 0 || this.arancelesLength > 0) {
        this.openConfirmDialog = true;
      } else {
        this.applyHeredar();
      }
    },
    async applyHeredar() {
      this.loadingBtn = true;
      const data = {
        tabIdHeredada: this.nombreTablaSelected.id,
        // error de arrastre en toda la pantalla de tablas de facturacion, el objeto 'tabla' tiene 'tabId' cuando creas una nueva,
        // pero 'tablaId' al editar, lo que fue generando inconsistencias
        tabId: this.tabla.tablaId ?? this.tabla.tabId,
        redondeo: this.tipoRedondeoSelected.id ?? 0,
        // porcentajeAumentoCtaFac: parseFloat(this.porcentajeAumentoFac ?? 0),
        porcentajesPorNomenclador: this.porcentajesPorNomItems,
        porcentajeAumentoAranceles: parseFloat(this.porcentajeAumentoAran ?? 0)
      };
      try {
        const response = await this.heredarTablaFac(data);
        if (response === true) {
          this.loadingBtn = false;
          this.setAlert({
            type: "success",
            message: "Tabla heredada con éxito."
          });
          this.$emit("setPractica");
          this.$emit("setAranceles");
        } else this.loadingBtn = false;

        this.closeModal();
      } catch {
        this.loadingBtn = false;
      }
    },
    tablaChange(data) {
      this.nombreTablaSelected = data;
    },
    customFilterTablas(item, queryText, itemText) {
      return item.id || item.value.indexOf(queryText) > -1;
    },
    async getNomencladoresTablaFac() {
      if (this.nombreTablaSelected != null) {
        try {
          this.loadingNomencladores = true;
          // const tabIds = [].push(this.nombreTablaSelected.id);
          const tabIds = [this.nombreTablaSelected.id];
          // tabIds.push(this.nombreTablaSelected.id);
          const nomencladoresPorTablas = await this.getNomencladoresTablas(
            tabIds
          );
          this.porcentajesPorNomItems = nomencladoresPorTablas;
        } catch (error) {
        } finally {
          this.loadingNomencladores = false;
        }
      }
    },
    getTablasFilter() {
      if (this.timerTablas) {
        clearTimeout(this.timerTablas);
        this.timerTablas = null;
      }
      this.timerTablas = setTimeout(async () => {
        if (
          this.nombreTablas?.some(x => x.value == this.tablasAutocompleteInput)
        ) {
          return;
        } else if (!this.banderaTablaSelected) {
          this.tablasLoading = true;
          const response = await this.getTablasFacturacion({
            input: this.tablasAutocompleteInput
          });
          this.$refs["tablas-autocomplete"].cachedItems = [];
          this.nombreTablas = response;
          this.tablasLoading = false;
        } else {
          this.tablasLoading = true;
          //toString: para mandar el dato al backend como string
          const response = await this.getTablasFacturacion({
            input: this.infoNuevaActualizacion.nombreTabla.toString()
          });
          this.nombreTablas = response;
          this.nombreTablaSelected = response[0].id;
          this.banderaTablaSelected = false;
          this.tablasLoading = false;
        }
      }, 1000);
    },
    closeModal() {
      this.$emit("closeModalHeredar");
    }
  }
};
</script>
<style scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
