<template>
  <v-container>
    <v-row>
      <v-col class="p-0 pt-2 pl-3" cols="12" md="1">
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
      </v-col>
      <v-col cols="12" md="11" class="p-0">
        <v-card-title class="p-0">
          <PageHeader :title="formEditTitle" />
        </v-card-title>
      </v-col>
    </v-row>
    <v-card class="p-3">
      <v-form
        v-model="isFormValidDatos"
        ref="filters-form"
        form="filters-form"
        id="filters-form"
        @submit.prevent="saveNombreTab()"
      >
        <v-row>
          <v-col cols="12" sm="4" class="py-0">
            <v-text-field
              type="text"
              outlined
              dense
              :disabled="banderaSave == true"
              :filled="banderaSave == true"
              label="Nombre de tabla"
              :rules="[
                rules.requiredTrim(nameTabla),
                rules.maxLength(nameTabla, 100)
              ]"
              v-model="nameTabla"
              autocomplete="off"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="8" class="py-0">
            <v-text-field
              type="text"
              outlined
              dense
              :disabled="banderaSave == true"
              :filled="banderaSave == true"
              label="Observaciones"
              v-model="obsTabla"
              :rules="[
                rules.requiredTrim(obsTabla),
                rules.maxLength(obsTabla, 100)
              ]"
              autocomplete="off"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-right m-0 py-0">
            <v-card-actions class="py-0">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                :disabled="!isFormValidDatos || banderaSave == true"
                small
                type="submit"
                form="filters-form"
                :loading="btnIsLoading"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-row>
      <v-col cols="12" class="text-right py-0">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="to-right"
                outlined
                small
                :disabled="idTab == null"
                v-on="on"
                v-bind="attrs"
                @click="exportTabla()"
              >
                Exportar
              </v-btn>
            </template>
            <span>Exportar tabla en formato importación</span>
          </v-tooltip>
          <v-btn
            color="primary"
            @click="importTabla()"
            small
            class="to-right"
            :disabled="idTab == null"
          >
            Importar
          </v-btn>
          <v-btn
            color="primary"
            @click="heredarTab()"
            small
            class="to-right"
            :disabled="idTab == null"
          >
            Heredar
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
    <v-card>
      <v-row justify="end" class="mt-3 mb-2">
        <v-col col="9" class="pt-0 pb-0" align="start" align-self="center">
          <v-btn-toggle dense color="primary" group>
            <v-btn
              value="ARANCELES"
              :disabled="mostrarTablaPracticas"
              @click="changeTab(1)"
              >Conjunto de prácticas</v-btn
            >
            <v-btn
              value="PRÁCTICAS"
              :disabled="mostrarTablaAranceles"
              @click="changeTab(2)"
              >Conjunto de aranceles</v-btn
            >
          </v-btn-toggle>
        </v-col>
      </v-row>
    </v-card>
    <div v-if="mostrarTablaAranceles">
      <v-data-table
        :headers="headersAranceles"
        :items="itemsAranceles"
        class="elevation-1"
        :loading="isLoadingAranceles"
        :page.sync="pagination"
      >
        <!-- <template v-slot:[`item.valor`]="{ item }">
          <span>{{ addDecimals(item.valor) }}</span>
        </template> -->
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="10">
                <v-text-field
                  v-model="search"
                  :append-icon="searchIcon"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="2" align="end">
                <v-btn
                  color="primary"
                  class="to-right"
                  :disabled="idTab == null"
                  @click="openModalArancel()"
                >
                  Nuevo
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                class=""
                @click="openModalArancel(item)"
              >
                {{ editIcon }}
              </v-icon>
            </template>
            <span>Editar arancel</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="deleteArancel(item.araUniId)"
              >
                {{ deleteIcon }}
              </v-icon>
            </template>
            <span>Eliminar arancel</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
    <div v-if="mostrarTablaPracticas">
      <v-data-table
        :headers="headersPractica"
        :items="itemsPractica"
        class="elevation-1"
        :search="search"
        :loading="isLoadingPractica"
        :page.sync="pagination"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="10">
                <v-text-field
                  v-model="search"
                  :append-icon="searchIcon"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="2" align="end">
                <v-btn
                  color="primary"
                  class="to-right"
                  @click="openModalEditPractica()"
                  :disabled="idTab == null"
                >
                  Nuevo
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                class=""
                @click="openModalEditPractica(item)"
              >
                {{ editIcon }}
              </v-icon>
            </template>
            <span>Editar practica</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="deletePractica(item.ctaFacId)"
              >
                {{ deleteIcon }}
              </v-icon>
            </template>
            <span>Eliminar práctica</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
    <v-dialog
      v-if="openModalImportar"
      v-model="openModalImportar"
      max-width="85%"
      @keydown.esc="closeModalImportar"
      persistent
    >
      <ImportarTab
        :tabId="idTab"
        @closeModalImportar="closeModalImportar"
        @setPractica="setPractica"
        @setAranceles="setAranceles"
        :ctaFacCount="itemsPractica.length"
        :tabla="nuevaTab"
      ></ImportarTab>
    </v-dialog>
    <v-dialog
      v-if="openModalHeredar"
      v-model="openModalHeredar"
      max-width="55rem"
      @keydown.esc="closeModalHeredar"
      persistent
    >
      <HeredarTabla
        :tabla="nuevaTab"
        :practicasLength="itemsPractica.length"
        :arancelesLength="itemsAranceles.length"
        @closeModalHeredar="closeModalHeredar"
        @setPractica="setPractica"
        @setAranceles="setAranceles"
      ></HeredarTabla>
    </v-dialog>
    <v-dialog
      v-if="openModalEditPrac"
      v-model="openModalEditPrac"
      max-width="60%"
      @keydown.esc="closeModalPractica"
      persistent
    >
      <NuevaPractica
        :nuevaTab="nuevaTab"
        :tablaPrac="tablaPrac"
        @setPractica="setPractica"
        @closeModalPractica="closeModalPractica"
      ></NuevaPractica>
    </v-dialog>
    <v-dialog
      v-if="openModalAran"
      v-model="openModalAran"
      max-width="60%"
      @keydown.esc="closeModalArancel"
      persistent
    >
      <NuevoArancel
        :tablas="nuevaTab"
        :itemsAranceles="itemsAranceles"
        :tablaAran="tablaAran"
        @itemsAran="itemsAran"
        @setAranceles="setAranceles"
        @closeModalArancel="closeModalArancel"
      ></NuevoArancel>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDeleteArancel"
      :openDelete.sync="showDeleteModalArancel"
      :maxWidth="'37%'"
      @onDeleteItem="confirmDelete()"
    />
    <DeleteDialog
      :titleProp="titleDeletePractica"
      :maxWidth="'37%'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDeletePrac()"
    />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import GoBackBtn from "@/components/shared/GoBackBtn";
import rules from "@/utils/helpers/rules";
import DeleteDialog from "@/components/shared/DeleteDialog";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import NuevaPractica from "@/components/modules/prestadores/NuevaPractica.vue";
import FiltersSelected from "@/components/shared/FiltersSelected";
import NuevoArancel from "@/components/modules/prestadores/NuevoArancel.vue";
import ImportarTab from "@/components/modules/prestadores/ImportarTab.vue";
import HeredarTabla from "@/components/modules/prestadores/HeredarTabla.vue";
import getFormatPrice from "@/utils/helpers/getFormatPrice";
import PageHeader from "@/components/ui/PageHeader.vue";
import helpersExcel from "@/utils/helpers/importExportExcel";

export default {
  name: "NuevaTablaFacturacion",
  directives: { mask },
  components: {
    FiltersSelected,
    CurrencyInput,
    GoBackBtn,
    DeleteDialog,
    NuevaPractica,
    NuevoArancel,
    ImportarTab,
    HeredarTabla,
    PageHeader
  },
  data: () => ({
    titleDeleteArancel: "¿Desea eliminar el arancel seleccionado?",
    titleDeletePractica: "Desea eliminar la práctica seleccionada?",
    showDeleteModalArancel: false,
    showDeleteModal: false,
    openModalImportar: false,
    filtersApplied: [],
    openModalHeredar: false,
    nameTabla: null,
    obsTabla: null,
    openModalAran: false,
    routeToGo: "ConsultaTablaFacturacion",
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    openModalEditPrac: false,
    searchIcon: enums.icons.SEARCH,
    porcentajeIcon: enums.icons.PERCENT_OUTLINE,
    search: "",
    itemsAran: [],
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    itemsHeredarCtaFac: [],
    nombreTablaSelected: null,
    nombreTablas: [],
    tablasAutocompleteInput: null,
    tablasLoading: false,
    opcionSelected: null,
    isFormValid: false,
    isFormValidDatos: false,
    isFormValidImportar: false,
    isFormValidPracticas: false,
    isFormValidImport: false,
    isFormValidAranceles: false,
    formEditTitle: "Editar tabla de facturación",
    newTitle: "Nueva tabla de facturación",
    calendarIcon: enums.icons.CALENDAR,
    btnIsLoading: false,
    rules: rules,
    observaciones: null,
    tablaNombre: null,
    mostrarTablaImportar: false,
    mostrarTablaHeredar: false,
    mostrarTablaManual: true,
    loading: false,
    mostrarTablaPracticas: true,
    mostrarTablaAranceles: false,
    loadingProcesarBtn: false,
    isLoadingHeredar: false,
    excelData: [],
    exportarTabla: [],
    modalExportarExcel: false,
    archivoEnProceso: false,
    file: null,
    registrosError: [],
    text: "",
    dataPractica: {},
    fileName: null,
    formatoTitle: "Formato de archivo",
    headers: [],
    datosFormatoArchivo: [],
    showSeeFormatModal: false,
    headersPractica: [
      {
        text: "Nomenclador",
        value: "nomenclador",
        sortable: false
      },
      {
        text: "Código desde",
        value: "codigoDesde",
        sortable: false,
        align: "end"
      },
      {
        text: "Código hasta",
        value: "codigoHasta",
        sortable: false,
        align: "end"
      },
      {
        text: "Área",
        value: "area",
        sortable: false,
        align: "end"
      },
      {
        text: "Especialista",
        value: "especialidad",
        sortable: false,
        align: "end"
      },
      {
        text: "Anestesista",
        value: "anestesia",
        sortable: false
      },
      {
        text: "Ayudante",
        value: "ayudante",
        sortable: false,
        align: "end"
      },
      {
        text: "Gastos",
        value: "gastos",
        sortable: false,
        align: "end"
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    isLoadingAranceles: false,
    itemsAranceles: [],
    itemsHeredarArancel: [],
    headersAranceles: [
      {
        text: "Unidad",
        value: "unidad",
        sortable: false
      },
      {
        text: "Valor",
        value: "valorString",
        sortable: false,
        align: "end"
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    itemsPractica: [],
    isLoadingPractica: false,
    pagination: null,
    nuevaTab: {},
    nomTabConsulta: null,
    nomObsTabla: null,
    idtablaConsulta: null,
    banderaSave: false,
    idTab: null,
    facId: null
  }),
  created() {
    if (this.$route.params.newTabla) {
      this.nuevaTab = this.$route.params.newTabla;
      this.idTab = this.nuevaTab.tablaId;
      this.setTablaFac();
    } else {
      this.newTablaFac();
    }
  },
  methods: {
    ...mapActions({
      saveTablaFact: "prestadores/saveTablaFact",
      getTablaFacturacionAranceles: "prestadores/getTablaFacturacionAranceles",
      deleteCuentaFac: "prestadores/deleteCuentaFac",
      deleteAran: "prestadores/deleteAran",
      getTablaFacturacionDetalle: "prestadores/getTablaFacturacionDetalle",
      exportTablaFacturacionImportacion:
        "prestadores/exportTablaFacturacionImportacion",
      setAlert: "user/setAlert"
    }),
    openModalEditPractica(item) {
      this.openModalEditPrac = true;
      this.tablaPrac = item ?? {};
    },
    importTabla() {
      this.openModalImportar = true;
    },
    closeModalImportar() {
      this.openModalImportar = false;
    },
    heredarTab() {
      this.openModalHeredar = true;
    },
    closeModalHeredar() {
      this.openModalHeredar = false;
    },
    closeModalPractica() {
      this.openModalEditPrac = false;
    },
    openModalArancel(item) {
      this.openModalAran = true;
      this.tablaAran = item;
    },
    closeModalArancel() {
      this.openModalAran = false;
    },
    changeTab(num) {
      switch (num) {
        case 1:
          this.mostrarTablaPracticas = true;
          this.mostrarTablaAranceles = false;
          break;
        case 2:
          this.mostrarTablaAranceles = true;
          this.mostrarTablaPracticas = false;
          break;
      }
    },
    async setPractica() {
      this.isLoadingPractica = true;
      try {
        const data = await this.getTablaFacturacionDetalle(this.nuevaTab.ctaId);
        this.itemsPractica = data;
        this.facId = this.itemsPractica.find(x => x.ctaFacId);
        this.isLoadingPractica = false;
      } catch {
        this.isLoadingPractica = false;
      }
    },
    async setAranceles() {
      this.isLoadingAranceles = true;
      const data = await this.getTablaFacturacionAranceles(this.idTab);
      this.itemsAranceles = data.lista;
      // this.itemsAranceles.forEach(x => x.valor = new Intl.NumberFormat("de-DE").format(x.valor),)
      this.isLoadingAranceles = false;
    },
    addDecimals(x) {
      return getFormatPrice(x);
    },
    async setTablaFac() {
      this.nuevaTab.tablaId;
      this.nameTabla = this.nuevaTab?.tablaNombre;
      this.obsTabla = this.nuevaTab?.observaciones;
      this.setPractica();
      this.setAranceles();
    },
    async newTablaFac() {
      this.formEditTitle = this.newTitle;
    },
    async saveNombreTab() {
      const data = {
        tabId: this.idTab ? this.idTab : null,
        tabNom: this.nameTabla,
        tabObs: this.obsTabla ? this.obsTabla : null
      };
      try {
        this.btnIsLoading = true;
        const res = await this.saveTablaFact(data);
        // a corregir --> dos asignaciones al objeto 'tabla' que se usan para lo mismo (pasar props)
        // ojo que el 'nuevaTab' se asigna al crear componente cuando se edita pero con tablaId en vez de tabId
        this.nuevaTab = res.data.data;
        this.idTab = this.nuevaTab.tabId;
        if (res.status === 200) {
          this.banderaSave = true;
          this.setAlert({ type: "success", message: "Guardado con éxito." });
          this.closeModal();
          this.btnIsLoading = false;
        }
      } catch (error) {
        this.btnIsLoading = false;
      }
    },
    async exportTabla() {
      const data = await this.exportTablaFacturacionImportacion(this.idTab);
      this.exportarTabla = data;
      this.exportTablaImport();
    },
    exportTablaImport() {
      let result = [];
      this.exportarTabla?.forEach(x =>
        result.push({
          ["Nomenclador"]: x.nomenclador,
          ["Codigo desde"]: x.codigoDesde,
          ["Codigo hasta"]: x.codigoHasta,
          ["Area"]: x.area,
          ["Importe especialista"]: x.ctaEspecialista,
          ["Importe ayudante"]: x.ctaAyudante,
          ["Importe anestesista"]: x.ctaAnestesista,
          ["Importe gastos"]: x.ctaGastos,
          ["Unidad especialista"]: x.uniEspecialista,
          ["Unidad ayudante"]: x.uniAyudante,
          ["Unidad anestesista"]: x.uniAnestesista,
          ["Unidad gastos"]: x.uniGastos
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(
        formato,
        `Formato importación tabla ${this.idTab}`
      );
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    deletePractica(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDeletePrac() {
      const response = await this.deleteCuentaFac(this.idToDelete);
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.setPractica();
      }
    },
    deleteArancel(id) {
      this.showDeleteModalArancel = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteAran(this.idToDelete);
      if (response.status === 200) {
        this.showDeleteModalArancel = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.setAranceles();
      }
    }
  }
};
</script>
<style scoped></style>
